@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.box{
    padding: 20px;             
    border-radius: 20px;        
    display: flex;             
    flex-direction: column;    
    text-align: center;          
    color: black;              
    margin: 10px 0;            
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    width: 360px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: #d1dee7;
}

.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    border-radius: 20px;
    font-family: "Comfortaa", sans-serif;
}

.title{
    font-size: 1.5rem;
    font-weight: 700;
}

.inputField {
    width: 90%; 
    padding: 12px; 
    font-size: 16px; 
    border: none; 
    border-radius: 10px; 
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); 
    outline: none; 
}

.btn {
    width: 90%; 
    padding: 12px; 
    background-color: #1D6AB4; 
    color: white;
    font-size: 16px; 
    border: none; 
    border-radius: 10px; 
    cursor: pointer;
    text-align: center; 
    margin-top: 10px;
}

.btn:hover {
    background-color: #155a8f; 
}

.logo{
    width: 160px;
}