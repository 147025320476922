/* Global styles */
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 100%; /* Ensures the height extends based on content */
}

body {
  background-image: url('../public/bg3.jpg'); /* Adjust the path if needed */
  background-size:auto;
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  font-family: "Comfortaa", sans-serif;
}

/* Ensure content is centered and flex-based layout is used */
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Takes at least full viewport height */
  text-align: center;
  overflow-x: hidden; 
}

button {
  margin: 5px;
}

.nav-link {
  z-index: 999;
}


.table, .table2, .table3 {
  max-width: 100%;
  height:450px;
  /* border: solid 1px black; */
  margin: 2.5px;
  display: grid;
  overflow-x: scroll;
  white-space: nowrap;
  text-align: center;
}

.table {
  padding: 20px;
}

/* .table2 {
  grid-template-columns: repeat(84, 1fr);
} */

.table3 {
  grid-template-columns: repeat(2, 1fr);
  padding: 7px;
}

.table__header{
  padding: 10px;
  border: solid 1px gray;
}

.table__data {  
  padding: 10px;
  border: solid 0.5px gray;
}

.table__data p {
  background: transparent;
}

.input-div{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputs__inner {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
}

.inputs__inner input, select {
  margin: 15px;
}

.card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.search-card {
  width: 80%;
  padding: 20px;
  border: solid 1px black;
  border-radius: 10px;
}

.info-container{
  width: 100%;
  height: 50%;
  display:flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.info-left, .info-right{
  width: 50%;
  display: flex;
  flex-direction: column;
}

.family-container{
  height: 50%;
}

.family-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.desc_col {
  text-align: left;
}
 
.member-form {
  width:500px;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;   
  height: 100%;
  padding-top: 20px;                   
  background-color: rgba(255, 255, 255, 0.7); 
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}
.member-form div{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.table-container {
  max-width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;   
  height: 360px;                     
  background-color: rgba(255, 255, 255, 0.7); 
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 80px;
  margin-top: 20px;
}

/* Grid table styling */
.table {
  display: grid;
  width: 100%;
  height: 100%; /* Ensure the table takes the full width of its container */
  gap: 0px;   /* Adds space between grid items */
}

.table__header {
  font-weight: bold;
  text-align: center;
}

.table__data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
}
